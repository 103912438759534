<template>
  <div id="organisation-dashboard">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <b-alert show id="organisationDashboardAlert"
      >Showing organisation dashboard. This is not a user's dashboard.<br />
      Organisation state is estimated. Go to
      <b-link :to="{ name: 'all-users' }">Users</b-link> to access a user's dashboard.<br
    /></b-alert>

    <div v-if="loading" class="text-center">
      <Busy primary :size="4" />
      <p class="mt-4 mb-2 text-primary">Building organisation dashboard</p>
    </div>

    <b-container v-else>
      <ClientOrganisation
        :key="organisation.organisationId"
        :organisation="organisation"
        :state="organisation.state"
        :link="{
          roles: [{ id: 'full_member' }]
        }"
        :report="savingreport"
      />
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#organisation-dashboard {
  #organisationDashboardAlert {
    text-align: center;
    margin-top: -3em;
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import ClientOrganisation from "@/components/ClientOrganisation";

export default {
  name: "organisation-dashboard",
  components: {
    Busy,
    ClientOrganisation
  },
  data() {
    return {
      title: "OrganisationDashboard",
      loading: true,
      organisation: null,
      links: null,
      savingreport: null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        let response = await Promise.all([this.getOrganisation(), this.getLatestReport()]);
        this.setOrganisationState();
      } finally {
        this.loading = false;
      }
    },

    async getOrganisation() {
      const organisationId = this.$route.params.organisationId;

      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}organisations/${organisationId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.organisation = response.data.organisation;
          }
        })
        .catch((e) => {
          EventBus.$emit("show-toast", {
            message: "There was a problem getting the organisation data.",
            variant: "warning"
          });
          console.error(e);
        });
    },
    async getLatestReport() {
      const organisationId = this.$route.params.organisationId;

      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}savings?summarised=true&organisationId=${organisationId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.savingreport = response.data.savings[0];
          }
        })
        .catch((e) => {
          EventBus.$emit("show-toast", {
            message: "There was a problem getting the savings report data.",
            variant: "warning"
          });
          console.error(e);
        });
    },
    setOrganisationState() {
      //Estimate organisation state. Does not check latest extract jobs for efficiency.
      if (this.savingreport) {
        this.organisation["state"] = "REPORT_COMPLETE";
      } else {
        this.organisation["state"] = "NOT_REQUESTED";
      }
    }
  }
};
</script>